
<!-- 人员统计 -->
<template>
  <div class="person-wrapper flex">
    <div class="search-content searchBox">
      <div class="search-item">
        <label>维修人员</label>
        <el-input type="text" v-model="filter.repairUserName" placeholder="请输入" size="medium" clearable></el-input>
      </div>
      <div class="search-item">
        <label>工单完成时间</label>
        <el-date-picker class="range-input"
                        :default-time="['00:00:00','23:59:59']"
                        size="medium"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        is-range
                        v-model="time"
                        range-seperator="至"
                        start-placeholder="开始时间"
                        @change="timeChange"
                        end-placeholder="结束时间"></el-date-picker>
      </div>
      <div class="search-item button-group pdl16">
        <el-button type="primary" class="btn-search" @click="search()" size="medium">查询</el-button>
        <el-button type="primary" class="btn-search" @click="exportTable()" size="medium">导出</el-button>
        <el-button  class="btn-reset" @click="time=[];resetSearch()" size="medium">重置</el-button>
      </div>
    </div>
    <div class="table-content inline-flex contentBox">
      <el-table id="export_table" :data="tableData" class="contentTable" size="medium" row-key="id" :tree-props="{children:'child',hasChildren:'hasChildren'}">
        <el-table-column prop="repairUserName" label="维修人员"></el-table-column>
        <el-table-column prop="totalCount" label="总工单"></el-table-column>
        <el-table-column prop="finishCount" label="已完成工单"></el-table-column>
        <el-table-column prop="unfinishedCount" label="未完成工单"></el-table-column>
        <el-table-column prop="refuseCount" label="拒绝工单"></el-table-column>
        <el-table-column prop="goodCount" label="好评"></el-table-column>
        <el-table-column prop="badCount" label="差评"></el-table-column>
        <el-table-column prop="commonCount" label="一般评价"></el-table-column>
        <el-table-column prop="normalCount" label="默认评价"></el-table-column>
        <el-table-column prop="avgTime" label="平均响应时长"></el-table-column>
      </el-table>
      <reconova-pagination @paginationChange="paginationChange" :page-no="pageIndex" :page-size="pageSize" :total="total"></reconova-pagination>
    </div>
    <div class="dialog-wrapper">
    </div>
  </div>
</template>

<script>
import ReconovaPagination from "@/components/reconova/reconovaPagination";
import pagination from "@/mixins/pagination";
import {onDownload} from "../../../../utils/utils";

export default {
  name: "personnel-statistics",
  components: {
    ReconovaPagination
  },
  mixins:[pagination],
  data(){
    return {
      filter:{
        repairUserName:'',
        finishStartTime:'',
        finishEndTime:''
      },
      time:[],
      tableData:[]
    }
  },
  mounted(){
    this.search()
  },
  methods:{
    // 查询
    search(pageSize=10,pageIndex=1){
      let requestParam = this.getParams(pageSize,pageIndex);
      this.$api.property.personStatistic(requestParam).then(response=>{
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.pageIndex = response.data.pageNum;
        this.pageSize = response.data.pageSize;
      })
    },

    timeChange(value){
      this.filter.finishStartTime = value?value[0]:'';
      this.filter.finishEndTime = value?value[1]:'';
    },

    exportTable(){
      let param = this.getParams()
      this.$api.property.exportPersonStatistic(param).then(res=>{
        let { headers, data } = res;
        onDownload(headers, data);
      })
    }
  }
}
</script>

<style scoped>
.el-input,.el-select {
  width: 220px;
}

.el-table{
  flex: 1;
  overflow-y: auto;
}
</style>
const pagination =  {
    data(){
        return {
            filter:{

            },
            pageSize:10,
            pageIndex:1,
            total:0,
            //设置时间可选范围
            pickerOptions: {
                disabledDate(date){
                    return date > new Date(new Date().setHours(23,59,59)).getTime()
                }
            },
        }
    },
    methods:{
        getParams(pageSize='',pageIndex=''){
            return {
                ...this.filter,
                pageSize:pageSize,
                pageNum:pageIndex
            }
        },

        // 每个引入的组件必须要重写这个方法
        search(pageSize=10,pageIndex=1){
            // ...
        },

        paginationChange(type,value){
            let size,index;
            if(type==='pageSize'){
                index = 1;
                size = value;
            }else{
                index = value;
                size = this.pageSize;
            }
            this.search(size,index)
        },

        // 重置
        resetSearch(){
            for(let key in this.filter){
                this.filter[key] = ''
            }
            this.search()
        },
    }
}

export default pagination